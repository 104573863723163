import React from "react"

import Header from "@ecom/ui/components/HeaderNew"
import { themeBlack } from "@ecom/ui/style/themeBlack"
import Layout from "../components/Layouts/mainPage"
import NoIndex from "../components/NoIndex"
import { DeprecatedFormList } from "../components/DeprecedatedFormList"

const fields = [
  "fio",
  "phone",
  "birthDate",
  "region",
  "organization",
  "products",
  "questionsAndSuggestions",
]

export default function Page() {
  return (
    <Layout theme={themeBlack} noHeader noFooter>
      <NoIndex />
      <Header right={<div />} />
      <DeprecatedFormList fields={fields} successList />
    </Layout>
  )
}
