import { organizationValidator } from "@ecom/ui/components/FormLanding/validators/organizationValidator"

interface IValidatorInnProps {
  dadataValue: object
  inputValue: string
  isDadataValueActual: boolean
}

const weightedSum = (array: number[], coefficients: number[]) => {
  let sum = 0
  for (let i = 0; i < array.length; i += 1) {
    sum += coefficients[i] * array[i]
  }
  return sum
}

const checkInn = (inn: string) => {
  if (!(inn.match(/^\d{10}$/) || inn.match(/^\d{12}$/))) {
    return "Выберите значение из списка или введите ИНН"
  }

  const innArray = []

  for (let i = 0; i < inn.length; i += 1) {
    innArray.push(Number(inn[i]))
  }

  if (inn.length === 10) {
    if ((weightedSum(innArray, [2, 4, 10, 3, 5, 9, 4, 6, 8, 0]) % 11) % 10 === innArray[9]) {
      return null
    }
    return "Ошибка при проверке контрольного числа"
  }

  if (inn.length === 12) {
    const sum1 = (weightedSum(innArray, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0, 0]) % 11) % 10
    const sum2 = (weightedSum(innArray, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0]) % 11) % 10
    if (sum1 === innArray[10] && sum2 === innArray[11]) {
      return null
    }
    return "Ошибка при проверке контрольного числа"
  }

  return null
}

export function validatorInnKreditIp(inn: IValidatorInnProps) {
  if (!inn.inputValue) {
    return "Выберите значение из списка или введите ИНН"
  }

  if (!organizationValidator(inn)) {
    return null
  }

  return checkInn(inn.inputValue)
}

export default function validatorInn(inn: IValidatorInnProps) {
  if (!inn.inputValue) {
    return "Введите наименование организации"
  }

  if (!organizationValidator(inn)) {
    return null
  }

  return checkInn(inn.inputValue)
}
