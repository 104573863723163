import getCookie from "@ecom/ui/utils/getCookie"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { navigate } from "gatsby"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import sendRequest from "@ecom/ui/utils/sendRequest"
import { events } from "../../helpers/events"

import { FileData, PersonalInfoFormDataFormatted } from "./types"

const DEFAULT_CONFIG = {
  defaultSum: 150000,
  min: 150000,
  max: 30000000,
}

export const DEFAULT_SUM_CONFIG = {
  min: DEFAULT_CONFIG.min,
  max: DEFAULT_CONFIG.max,
}

export const DEFAULT_FIELDS = ["fio", "birthDate", "phone", "region", "email"]

const fieldsType = {
  birthDate: "date",
  fio: "fio",
  region: "region",
  localityAddress: "dadataAddress",
}

const convertToBase64 = async (file: File) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => resolve(error.toString())
  })

export function formatValuesToRequest(values: any, hasFullName: boolean) {
  const valuesToRequest = formatToRequest(values, fieldsType)
  const { surname, name, patronymic } = valuesToRequest.fio

  valuesToRequest.fio = `${surname} ${name} ${patronymic}`
  if (!hasFullName) {
    delete valuesToRequest.fio
  }

  if (valuesToRequest.files) {
    const fileList: FileData[] = []
    valuesToRequest.files.forEach(async (file: File) => {
      const base64 = await convertToBase64(file)
      fileList.push({ name: file.name, content: base64 })
    })
    valuesToRequest.files = fileList
  }

  if (!valuesToRequest.email) {
    delete valuesToRequest.email
  }

  const formattedRequest: PersonalInfoFormDataFormatted = {
    surname,
    name,
    patronymic,
    ...valuesToRequest,
  }

  return formattedRequest
}

export function getProductName(product: string) {
  switch (product) {
    case "Кредит":
      return "Cash Loan"

    case "Халва":
      return "КК Халва"

    case "Ипотека":
      return "Ипотека Первичная"

    case "Вклад":
      return "Вклад с Халвой онлайн"

    default:
      return null
  }
}

export const getGAFB = () => {
  const ga = getCookie("_ga")
  const ga_arr = ga ? ga.split(".") : []
  const gaClientID = ga_arr[2] && ga_arr[3] ? `${ga_arr[2]}.${ga_arr[3]}` : ""

  const fbp = getCookie("_fbp")
  const fbp_arr = fbp ? fbp.split(".") : []
  const fbClientID = fbp_arr[2] && fbp_arr[3] ? `${fbp_arr[2]}.${fbp_arr[3]}` : ""

  return { gaClientID, fbClientID }
}

export const getIP = () =>
  fetch("https://api.ipify.org?format=json")
    .then((res) => {
      if (!res.ok) {
        throw Error(`HTTP error. Status code: ${res.status}`)
      }

      return res.json()
    })
    .then((data) => data.ip)

export const getRegionByPhone = (phone: string) =>
  fetch(`${process.env.GATSBY_APP_API_URL}/v1/region-detect?phone=${phone}`)
    .then((res) => {
      if (!res.ok) {
        throw Error(`HTTP error. Status code: ${res.status}`)
      }

      return res.json()
    })
    .then((data) => data.region)

export function validatorProducts(value: []) {
  if (value.length === 0) {
    return "Выберете продукт"
  }

  return null
}

export const sendToApi = ({ data, path, apiId }: SendToApiRequestProps) => {
  const additionalData = getAdditionalData()

  return sendRequest(
    `${process.env.GATSBY_APP_API_URL}${path}`,
    {
      ...data,
      additionalData,
    },
    apiId
  )
}

export const sendDataRest = (data: Record<string, any>, formPath: string, successList: boolean) => {
  sendToApi({
    data,
    path: formPath,
  })
    .then(() => {
      pushToDataLayer(events.applicationSend.approve)

      if (successList) {
        navigate("/success-list")
      } else {
        navigate("/success")
      }
    })
    .catch(() => {
      pushToDataLayer(events.applicationSend.error)
      pushToDataLayer(events.GAFormEvent.error)

      navigate("/error-technical/")
    })
}

export type RequestProps = {
  data: Record<string, any>
  url?: string
}

export type SendToApiResponse = {
  requestId: string
  status: string
  partnerExternalId?: number
}

export type SendToApiRequestProps = {
  data: Record<string, any>
  path: string
  apiId?: string
}
