// extracted by mini-css-extract-plugin
export var btnOuter = "deprecatedFormList-module--btnOuter--vN72U";
export var esiaDesk = "deprecatedFormList-module--esiaDesk--Fg8lf";
export var fieldOuter = "deprecatedFormList-module--fieldOuter--EPFDK";
export var formLanding = "deprecatedFormList-module--formLanding--+O0TQ";
export var formOuter = "deprecatedFormList-module--formOuter--IN34y";
export var formSection = "deprecatedFormList-module--formSection--9Smhk";
export var otherIndent = "deprecatedFormList-module--otherIndent--dArXw";
export var padding = "deprecatedFormList-module--padding--pJgmn";
export var personalData = "deprecatedFormList-module--personalData--wvO7U";
export var title = "deprecatedFormList-module--title--tHLnp";