import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"

import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import Acceptment from "@ecom/ui/components/FormLanding/fields/AcceptmentField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"

import REGIONS from "@ecom/ui/enums/regions"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import {
  sendDataRest,
  DEFAULT_FIELDS,
  formatValuesToRequest,
  getRegionByPhone,
  getProductName,
} from "./helper"
import { renderFields } from "./fields"

import type { FieldData } from "../../interfaces/fieldData"
import type { RegionData } from "./types"

import * as styles from "./deprecatedFormList.module.scss"
import { handleClickForm } from "../../helpers/WatcherDL/utils/handleClickForm"

const EMPTY_REGION_VALUE = { label: "", value: "", kladr_id: "" }

type ButtonProps = {
  buttonColor?: "primary" | "secondary"
  buttonText?: string
  buttonStyles?: string
}

export type FormProps = {
  fields?: string[]
  hasFullName?: boolean
  formTitle?: React.ReactNode
  formPath?: string
  buttonProps?: ButtonProps
  successList?: boolean
}

export function DeprecatedFormList({
  fields = DEFAULT_FIELDS,
  formTitle = "Лист обратной связи",
  hasFullName = false,
  buttonProps = {
    buttonColor: "primary",
    buttonText: "Отправить",
  },
  formPath = "/v2/app/public/start",
  successList = false,
}: FormProps) {
  const refForm = useRef<any>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [regionByPhone, setRegionByPhone] = useState<string>()
  const [region, setRegion] = useState(EMPTY_REGION_VALUE)

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (regionByPhone) {
      getRegionByPhone(regionByPhone).then((data) => {
        if (data) {
          refForm.current.setField("region", {
            value: REGIONS.find((reg: any) => reg.value.includes(data)),
            error: null,
            helperText: "",
          })
          setRegion(REGIONS.find((reg: any) => reg.value.includes(data)))
        }
      })
    }
  }, [regionByPhone])

  const handleSubmit = (values: any) => {
    pushToDataLayer({
      event: "buttonClick",
      name: buttonProps.buttonText,
      placement: "appl_halva",
    })

    setIsSubmitting(true)

    const valuesToRequest = formatValuesToRequest(values, hasFullName)

    const productName = getProductName(valuesToRequest.products[0])

    const data = {
      ...valuesToRequest,
      productName,
    }
    sendDataRest(data, formPath, successList)
  }

  const onChangeFields = {
    phone: ({ value }: FieldData<string>) => {
      if (value.replace(/\D/g, "").length === 11) {
        setRegionByPhone(value)
      }
    },
    region: ({ value }: FieldData<RegionData>) => {
      if (!value) {
        setRegion(EMPTY_REGION_VALUE)
        return
      }

      const newRegionValue = value
      setRegion(newRegionValue)
    },
    agree: ({ value }: FieldData<boolean>) => {
      setIsSubmitting(!value)
    },
  }

  return (
    <section className={styles.formSection}>
      <Container>
        <div className={styles.formOuter} id="personal-form" data-testid="form">
          <span ref={ref} className={styles.title}>
            {formTitle}
          </span>
          <FormLanding
            name="personalForm"
            onSubmit={handleSubmit}
            onChangeFields={onChangeFields}
            onClick={() => handleClickForm}
            ref={refForm}
            classes={{ container: styles.formLanding }}
          >
            {renderFields({
              fields,
              styles,
              region,
            })}
            <div className={styles.personalData}>
              <Acceptment name="agree" defaultValue validateOnBlur={false} />
            </div>
            <div className={cn(styles.btnOuter, "text-center")} data-testid="sendDataBtn">
              <SubmitButton
                disabled={isSubmitting}
                color={buttonProps.buttonColor}
                className={buttonProps.buttonStyles}
              >
                {buttonProps.buttonText}
              </SubmitButton>
            </div>
          </FormLanding>
        </div>
      </Container>
    </section>
  )
}
