import React from "react"

import DateField from "@ecom/ui/components/FormLanding/fields/DateField"
import DadataOrganization from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataOrganization"
import AutocompleteField from "@ecom/ui/components/FormLanding/fields/AutocompleteField"
import DadataFio from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataFio"
import MultipleSelectField from "@ecom/ui/components/FormLanding/fields/MultipleSelectField"
import TextField from "@ecom/ui/components/FormLanding/fields/TextField"
import REGIONS from "@ecom/ui/enums/regions"
import PhoneFieldWithSeven from "@ecom/ui/components/FormLanding/fields/PhoneFieldWithSeven"

import validatorInn from "./validator"
import { validatorProducts } from "./helper"

const items = ["Кредит", "Халва", "Ипотека", "Вклад"]

const FIELDS = {
  fio: ({ defaultValue }: any) => (
    <div data-testid="fio">
      <DadataFio label="ФИО клиента" name="fio" incomingValue={defaultValue} />
    </div>
  ),
  birthDate: ({ defaultValue }: any) => (
    <div data-testid="birthDate">
      <DateField
        name="birthDate"
        defaultValue={defaultValue}
        label="Дата рождения"
        placeholder="дд.мм.гггг"
        max={new Date()}
        validAgeMin={18}
        validAgeMax={85}
      />
    </div>
  ),
  organization: () => (
    <div>
      <DadataOrganization
        label="Наименование организации"
        name="organization"
        validate={validatorInn}
      />
    </div>
  ),
  phone: ({ defaultValue }: any) => (
    <div data-testid="phone">
      <PhoneFieldWithSeven label="Номер телефона" name="phone" defaultValue={defaultValue} />
    </div>
  ),
  region: () => (
    <div data-testid="region">
      <AutocompleteField name="region" label="Регион" options={REGIONS} />
    </div>
  ),
  products: () => (
    <MultipleSelectField
      name="products"
      label="Понравившийся продукт"
      items={items}
      validate={validatorProducts}
    />
  ),
  questionsAndSuggestions: () => (
    <TextField label="Вопросы и предложения" name="questionsAndSuggestions" multiline />
  ),
}

export const renderFields = ({ fields = [], styles, region, state = {}, settings = {} }: any) => {
  const fieldsWithData = [
    {
      name: "fio",
      defaultValue: [state.surname, state.name, state.patronymic]
        .filter((item) => Boolean(item))
        .join(" "),
    },
    { name: "birthDate", defaultValue: state.birthDate },
    { name: "organization", defaultValue: state.organization },
    { name: "phone", defaultValue: state.phone },
    { name: "region", defaultValue: "" },
    { name: "products", defaultValue: "" },
    { name: "questionsAndSuggestions", defaultValue: "" },
  ]
  const newFieldsWithData = fieldsWithData.filter(({ name }) => fields.includes(name))

  return (
    <>
      {newFieldsWithData.map(({ name, defaultValue }, i) => (
        <div className={styles.fieldOuter} key={`${i}${defaultValue}`}>
          {FIELDS[name as keyof typeof FIELDS]({
            region,
            defaultValue,
            settings,
          })}
        </div>
      ))}
    </>
  )
}
